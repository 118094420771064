.cmp-list__item button {
  cursor: pointer;
}

.cmp-dict__row:has(.remove-merchant-button:hover) {
  background-color: #f8f8f8;
}

.cmp-list__item:has(.cmp-list__btn-remove-item:hover),
.cmp-list__item:has(.cmp-list__btn-add-item:hover),
.cmp-dict__item:has(.cmp-dict__btn-remove-item:hover) {
  background-color: #f8f8f8;
}

.remove-merchant-button {
  cursor: pointer;
}

.cmp-list__dict-header {
  display: flex;
}

.cmp-list__dict-header > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cmp-list__dict-header p {
  white-space: nowrap;
}

.btn-list-dict:not(:disabled):not(:hover) {
  background-color: rgb(200, 200, 200);
  border-radius: 2px;
  border-width: 1px;
}
