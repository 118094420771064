.rti--container * {
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.rti--container {
  --rti-bg: #fff;
  --rti-border: #ccc;
  --rti-main: #3182ce;
  --rti-radius: 0.375rem;
  --rti-s: 0.5rem;
  --rti-tag: #edf2f7;
  --rti-tag-remove: #e53e3e;
  --rti-tag-padding: 0.15rem 0.25rem;

  /* Container Styles */
  align-items: center;
  background: var(--rti-bg);
  border: 1px solid var(--rti-border);
  border-radius: var(--rti-radius);
  display: flex;
  flex-wrap: wrap;
  gap: var(--rti-s);
  line-height: 1.4;
  padding: var(--rti-s);
}

.rti--container:focus-within {
  border-color: var(--rti-main);
  box-shadow: var(--rti-main) 0px 0px 0px 1px;
}

.rti--input {
  border: 0;
  outline: 0;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
}

.rti--tag {
  align-items: center;
  background: var(--rti-tag);
  border-radius: var(--rti-radius);
  display: inline-flex;
  justify-content: center;
  padding: var(--rti-tag-padding);
}

.rti--tag button {
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  line-height: inherit;
  padding: 0 var(--rti-s);
}

.rti--tag button:hover {
  color: var(--rti-tag-remove);
}
