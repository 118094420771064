.react-multi-email {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #fafafa;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}

.react-multi-email > div {
  flex-flow: wrap;
  max-width: 100%;
}

.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-email.focused {
  border-width: 2px;
  padding: calc(0.4em - 1px) calc(0.5em - 1px);
  border-color: #d04a02;
  background: #fafafa;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: rgba(0, 0, 0, 0.42) !important;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  background-color: #fafafa;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.4em 0.8em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

.div-style {
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
}
.div-dropdown {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr;
}
.react-multi-email-div {
  display: flex;
  gap: 50px;
  color: rgba(0, 0, 0, 0.42);
}

.react-multi-email-div--white .react-multi-email,
.react-multi-email-div--white .react-multi-email > input {
  background-color: #ffffff;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.42) !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.42) !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.42) !important;
}

.react-js-cron {
  color: grey;
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: #d04a02 !important;
}

.date-container {
  display: flex;
  gap: 20px;
}

.date-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-size: 18px;
  color: grey;
}

.date-span {
  font-size: 12px;
}

.MuiFormLabel-root.react-multi-email-label {
  top: -4px;
  max-width: calc(100% - 24px);
  transform: translate(14px, 16px) scale(1);
  z-index: 1;
  pointer-events: none;

  &[data-shrink='true'] {
    top: 0;
    color: #d04a02;
    z-index: 1;
    background-color: #fafafa;
    transform: translate(14px, -9px) scale(0.75);
    max-width: calc((100% - 24px) * 1.3);
  }
}
