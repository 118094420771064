/* Helvetica Neue - Font */
@font-face {
  font-family: 'HelveticaNeue-Thin';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/HelveticaNeueLTW01-35Thin.ttf');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/HelveticaNeueLTW01-75Bold.ttf');
}

@font-face {
  font-family: 'HelveticaNeue-BoldItalic';
  font-style: italic;
  font-weight: bold;
  src: url('./assets/fonts/HelveticaNeueLTW01-76BoldIt.ttf');
}

@font-face {
  font-family: 'HelveticaNeue-Medium';
  font-style: italic;
  font-weight: normal;
  src: url('./assets/fonts/HelveticaNeueLTW04-65Medium.ttf');
}

* {
  font-family: 'HelveticaNeue';
  font-size: 16px;
  line-height: 20px;
}

*.alt {
  font-family: 'HelveticaNeue';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'HelveticaNeue';
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 84px;
  line-height: 92px;
}

h2 {
  font-size: 64px;
  line-height: 72px;
}

h3 {
  font-size: 48px;
  line-height: 52px;
}

h4 {
  font-size: 36px;
  line-height: 40px;
}

h5 {
  font-size: 28px;
  line-height: 32px;
}

h6 {
  font-size: 21px;
  line-height: 24px;
}

strong,
b,
.bold {
  font-family: 'HelveticaNeue-Bold';
}

.noPadding-bottom {
  padding-bottom: 0;
}

.noPadding-top {
  padding-top: 0;
}

.noPadding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

.bold {
  font-weight: bold;
  margin: 0 !important;
}

.align-right {
  text-align: right;
}

.Mui-focused {
  color: black !important;
}

.Mui-checked {
  color: black !important;
}

.Mui-selected {
  background-color: #d2d7e2 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
}

button {
  box-shadow: none !important;
}

.rti--container {
  --rti-main: #d04a02 !important;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #d04a02 !important;
  color: white !important;
}

.ant-select-selector {
  border-color: black !important;
}

.Mui-selected {
  color: #d04a02 !important;
}

.Mui-focused {
  color: #d04a02 !important;
}

.MuiTabs-indicator {
  background-color: #d04a02 !important;
}

.MuiFormLabel-filled {
  color: #d04a02 !important;
}

/* MUI Accordion */

.MuiAccordion-root.MuiPaper-root {
  border: 0.5px solid #7d7d7d;
}

.MuiAccordion-root.MuiPaper-root:not(:last-child) {
  border-bottom: 0;
}

.MuiAccordion-root.MuiPaper-root:before {
  display: none;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  min-height: 48px;
  background-color: #dedede;
  flex-direction: row-reverse;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
}

.MuiButtonBase-root.MuiAccordionSummary-root
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}

.MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 12px 0;
  margin-left: 8px;
}

.MuiAccordion-region .MuiAccordionDetails-root {
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
  font-size: 0.9rem;
}
