.MuiFormControl-fullWidth {
  padding-bottom: 20px !important;
}

.data-source-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.data-source-type-div {
  display: grid;
  gap: 20px;
  grid-template-columns: 5fr 0.5fr;
}

.data-source-type-inner-div {
  border: 2px lightgray;
  padding: 20px;
  border-style: solid;
  overflow-x: auto;
}

.add-data-source-div {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-top: 20px;
}

.add-data-source-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-data-source-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.acquirer-button-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
