.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 100px;
}

.card-container {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 1165px) {
  .card-container {
    flex-direction: column;
  }
}
