.app-footer {
  height: 100px;
  background-color: black;
  color: white;
  padding: 15px;
}

.app-footer a {
  color: #9aa4be;
}

.app-footer a:hover {
  color: white;
}
