.merchant-div {
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 0.5fr;
}

.merchant-button-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.remove-merchant-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-merchant-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
