.column-validation-div {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 0.5px solid black;
}

.row-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.helper-text {
  white-space: pre-wrap;
}

.add-column-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-column-div {
  display: flex;
  gap: 10px;
  align-items: center;
}
